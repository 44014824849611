import React, { useEffect } from "react";

const ShareRedirect = () => {
  useEffect(() => {
    window.location.replace("https://store.luckystargold.com/share");
  }, []);

  return null; 
};

export default ShareRedirect;
